import tippy from 'tippy.js';

import { normalRequestProxy } from '../../lib/request-proxy.mjs';

class Component {
  onCreate(input) {
    const { notifications, hasUnread, user } = input;
    this.state = {
      hasUnread,
      notifications,
      user,
    };

    this.notificationComponent = null;
    this.tooltips = null;
    this.userNotificationComponent = null;
  }

  onMount() {
    if (window) {
      setTimeout(this.setupTooltips.bind(this), 1);
    }
  }

  setupTooltips() {
    if (!this.tooltips) {
      const container = this.getEl('notifications');
      this.tooltips = tippy('[data-tippy-user-notifications]', {
        arrow: false,
        maxWidth: 'none',
        appendTo: () => container,
      });
    }
  }

  destroyTooltips() {
    if (this.tooltips) {
      if (Array.isArray(this.tooltips)) {
        this.tooltips.forEach((i) => i.destroy());
      } else {
        this.tooltips.destroy();
      }
    }
    this.tooltips = null;
  }

  onNotificationClick(nid, idx, event) {
    const { target } = event;
    if (target.dataset?.action) {
      switch (target.dataset.action.toLowerCase()) {
        case 'read':
          this.markNotificationAsRead(nid, idx);
          break;
        case 'unread':
          this.markNotificationAsUnreadRead(nid, idx);
          break;
        case 'delete':
          this.deleteNotification(nid, idx);
          break;
        default:
          this.showNotification(nid, idx);
          break;
      }
      return;
    }

    this.showNotification(nid, idx);
  }

  checkUnreadNotifications() {
    const { notifications, hasUnread } = this.state;
    const checkUnread = notifications.some((n) => !n.is_read);

    this.setState('hasUnread', checkUnread);
    if (hasUnread !== checkUnread) {
      setTimeout(this.destroyTooltips.bind(this), 1);
      setTimeout(this.setupTooltips.bind(this), 1);
    }
  }

  async onError(data) {
    (await this.loadNotificationComponent()).onError(data);
  }

  async loadNotificationComponent() {
    if (!this.notificationComponent) {
      const template = await import(
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackExports: ["default", "named"] */
        '../app-notification/index.marko'
      );
      const render = await template.default.render();
      render.appendTo(document.body);

      this.notificationComponent = render.getComponent();
    }

    return this.notificationComponent;
  }

  async markNotificationAsRead(nid, idx, showError = true) {
    const { user, notifications } = this.state;

    const proxy = normalRequestProxy();
    const notification = notifications['' + idx];
    const readDate = new Date().toISOString();

    const data = new window.FormData();
    data.set('is_read', true);
    data.set('read', readDate);

    try {
      const response = await proxy.patch({
        path: `/_x/u/${user._id}/ns/${nid}/`,
        data,
      });
      if (response) {
        notification.is_read = true;
        notifications.read = readDate;

        setTimeout(this.checkUnreadNotifications.bind(this), 1);
        setTimeout(this.setStateDirty.bind(this), 1, 'notifications');
      }
    } catch (ex) {
      if (!showError) {
        this.onError(ex);
      }
      // TODO: report error
    }
  }

  async markNotificationAsUnreadRead(nid, idx) {
    const { user, notifications } = this.state;

    const proxy = normalRequestProxy();
    const notification = notifications['' + idx];

    const data = new window.FormData();
    data.set('is_read', false);

    try {
      const response = await proxy.patch({
        path: `/_x/u/${user._id}/ns/${nid}/`,
        data,
      });
      if (response) {
        notification.is_read = false;

        setTimeout(this.checkUnreadNotifications.bind(this), 1);
        setTimeout(this.setStateDirty.bind(this), 1, 'notifications');
      }
    } catch (ex) {
      this.onError(ex);
      // TODO: report error
    }
  }

  async deleteNotification(nid, idx) {
    const { user, notifications } = this.state;

    const proxy = normalRequestProxy();

    try {
      await proxy.delete({ path: `/_x/u/${user._id}/ns/${nid}/` });

      notifications.splice(idx, 1);

      setTimeout(this.checkUnreadNotifications.bind(this), 1);
      setTimeout(this.setStateDirty.bind(this), 1, 'notifications');
    } catch (ex) {
      this.onError(ex);
      // TODO: report error
    }
  }

  async loadUserNotificationComponent() {
    if (!this.userNotificationComponent) {
      const { user } = this.state;
      const template = await import(
        /* webpackMode: "lazy" */
        /* webpackExports: ["default", "named"] */
        '../user-notification-modal/index.marko'
      );

      const render = await template.default.render({ user });
      render.appendTo(document.body);

      this.userNotificationComponent = render.getComponent();
    }

    return this.userNotificationComponent;
  }

  async showNotification(nid, idx) {
    const component = await this.loadUserNotificationComponent();
    component.setup(nid);
    component.open();

    if (!this.state.notifications['' + idx].is_read) {
      setTimeout(this.markNotificationAsRead.bind(this), 10, nid, idx, false);
    }
  }
}

export default Component;
