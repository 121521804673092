/**
 * @constant {String}
 */
export const FIO_REQID_HEADER = 'x-fio-reqid';

/**
 * @constant {String}
 */
export const OTA_LITE_SERVICE_HEADER = 'ota-lite-svc';

/**
 * @constant {String}
 */
export const CONTENT_TYPE_HEADER = 'content-type';

/**
 * @constant {String}
 */
export const DEFAULT_CONTENT_TYPE = 'application/json; charset=UTF-8';

/**
 * @constant {String}
 */
export const ACCEPT_ENCODING = 'accept-encoding';

/**
 * @constant {String}
 */
export const CONSENT_COOKIE_NAME = 'fio_consent';

/**
 * @constant {String}
 * @default
 */
export const DEFAULT_TIME_ZONE = 'UTC';

/**
 * @constant {String}
 * @default
 */
export const DEFAULT_LOCALE = 'en';

/**
 * @constant {String}
 * @default
 */
export const GITHUB_LOGIN_PROVIDER = 'github';

/**
 * @constant {String}
 * @default
 */
export const GOOGLE_LOGIN_PROVIDER = 'google';

/**
 * @constant {String}
 * @default
 */
export const QUALCOMM_LOGIN_PROVIDER = 'qualcomm';

/**
 * @constant {String}
 * @default
 */
export const SHELL_LOGIN_PROVIDER = 'shell';

/**
 * @constant {Object}
 */
export const NORMALIZE_EMAIL_OPTIONS = {
  all_lowercase: true,
  gmail_remove_dots: false,
  gmail_remove_subaddress: false,
  gmail_convert_googlemaildotcom: false,
  outlookdotcom_lowercase: false,
  outlookdotcom_remove_subaddress: false,
  yahoo_lowercase: false,
  yahoo_remove_subaddress: false,
  icloud_lowercase: false,
  icloud_remove_subaddress: false,
};

/**
 * The social login providers with their label.
 * @constant {Array<Array<String,String>>}
 */
const externalProviders = [
  [GOOGLE_LOGIN_PROVIDER, 'Google'],
  [GITHUB_LOGIN_PROVIDER, 'GitHub'],
  [QUALCOMM_LOGIN_PROVIDER, 'Qualcomm'],
];

/**
 * List of supported external account/identity providers.
 * @constant {String[]}
 */
export const SUPPORTED_EXTERNAL_PROVIDERS = externalProviders.map((p) => p[0]);

/**
 * External account/identity providers and their labels.
 * @constant {Map}
 */
export const SUPPORTED_EXTERNAL_PROVIDERS_LABELS = new Map(externalProviders);

/**
 * Simplest way to check for an email in a correct format.
 * @constant {RegExp}
 */
export const EMAIL_REGEX =
  // eslint-disable-next-line security/detect-unsafe-regex
  /[A-Za-z0-9!#$%&'*+-/=?^_`{|}~]+@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*/i;
