import CookieConsent from '@foundriesio/cookie-consent';

import { CONSENT_COOKIE_NAME } from '../../utils/const/app.mjs';

const CONSENT_PREFERENCES_KEY = 'cookie-consent-preferences';

class Component {
  onMount() {
    const fioCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${CONSENT_COOKIE_NAME}=`));

    if (!fioCookie) {
      if (
        window.localStorage &&
        window.localStorage.getItem(CONSENT_PREFERENCES_KEY)
      ) {
        window.localStorage.removeItem(CONSENT_PREFERENCES_KEY);
      }
    }

    const cookieConsent = CookieConsent({
      acceptAllButton: false,
      labels: {
        // eslint-disable-next-line quotes
        description: `<p>This site makes use of third-party cookies as explained in our <a target="_blank" href="https://foundries.io/company/cookie-policy/">Cookie Policy</a>, <a target="_blank" href="https://foundries.io/company/privacy/">Privacy Policy</a> and <a target="_blank" href="https://foundries.io/company/terms/">Terms and Conditions</a>.</p>`,
      },
      cookies: [
        {
          id: 'essential',
          label: 'Essential',
          required: true,
          checked: true,
          description:
            'Technical cookies to enable a seamless experience while using Foundries.io websites and resources.',
        },
        {
          id: 'analytics',
          label: 'Analytics (non-essential)',
          required: false,
          checked: false,
          description:
            'Cookies needed to understand how users interact with Foundries.io websites and resources.',
        },
        {
          id: 'marketing',
          label: 'Marketing (non-essential)',
          required: false,
          description:
            'Cookies needed for marketing campaigns and advertising.',
        },
      ],
      dialogTemplate: function (templateVars) {
        const { PREFIX, config } = templateVars;
        return `
<aside id="${PREFIX}" class="${PREFIX} js-cookie-bar" role="dialog" aria-live="polite" aria-describedby="${PREFIX}-description" aria-hidden="true" tabindex="0">
  <div data-nosnippet>
    <!--googleoff: all-->
    <header class="${PREFIX}__header" id="${PREFIX}-description">
      <p class="title is-4">${config.get('labels.title')}</p>
      ${config.get('labels.description')}
    </header>
    <form>
      <button class="${PREFIX}__button" aria-label="${config.get(
          'labels.aria.button'
        )}">
        <span>${config.get('labels.button.default')}</span>
      </button>
    </form>
    <!--googleon: all-->
  </div>
</aside>`.trim();
      },
    });

    cookieConsent.on('update', (cookies) => {
      const cookieStr = cookies
        .filter((cookie) => cookie.accepted)
        .map((cookie) => `${cookie.id}:${cookie.accepted ? '1' : '0'}`)
        .join(',');
      document.cookie = `${CONSENT_COOKIE_NAME}=${cookieStr}; path=/; secure; max-age=31536000`;
    });

    window.CookieConsent = cookieConsent;
  }
}

export default Component;
