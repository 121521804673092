import tippy from 'tippy.js';

class Component {
  onMount() {
    setTimeout(() => {
      tippy('[data-tippy-factory]', {
        arrow: true,
        maxWidth: 'none',
        appendTo: () => document.body,
      });
    }, 0);
  }

  onBurgerClick(event, element) {
    event.preventDefault();

    element.classList.toggle('is-active');
    document
      .getElementById(element.dataset.target)
      .classList.toggle('is-active');
  }
}

export default Component;
