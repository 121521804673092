import { wrap } from 'listener-tracker';

const OPAQUE_REDIRECT_TYPE = 'opaqueredirect';
const NO_CORS_MODE = 'no-cors';
const CORS_MODE = 'cors';

const isOKStatus = (status) => status >= 200 && status <= 202;
const isCorsRequest = (mode) => mode === CORS_MODE;
const isOpaqueRequest = (mode) => mode === NO_CORS_MODE;

class RequestProxy {
  constructor(user) {
    this.user = user;

    this.path = null;
    this.data = null;
    this.headers = null;
    this.config = null;
    this.method = null;
    this.resolved = false;
    this.rawResponse = false;
  }

  csrfToken() {
    if (document) {
      return document.querySelector('meta[name="_csrf"]')?.content;
    }
  }

  static updateCsrfToken(value) {
    if (document && document.querySelector('meta[name="_csrf"]')) {
      document.querySelector('meta[name="_csrf"]').content = value;
    }
  }

  /**
   * Perform a POST request.
   * @param {String} args.path Path of the request
   * @param {Object} args.data The data to send
   * @param {Object} [args.headers={}] Extra headers to set for the request
   * @param {Object} [args.config={}] Extra config values to set for the request
   * @param {Boolean} [args.rawResponse] Return the raw response
   * @param {Boolean} [args.passHeaders] Return the headers as well
   * @returns {Promise<Object>}
   */
  post({ path, data, headers = {}, config = {}, rawReponse, passHeaders }) {
    return this.request({
      path,
      data,
      headers,
      config,
      method: 'POST',
      rawReponse,
      passHeaders,
    });
  }

  /**
   * Perform a PATCH request.
   * @param {String} args.path Path of the request
   * @param {Object} args.data The data to send
   * @param {Object} [args.headers={}] Extra headers to set for the request
   * @param {Object} [args.config={}] Extra config values to set for the request
   * @param {Boolean} [args.rawResponse] Return the raw response
   * @param {Boolean} [args.passHeaders] Return the headers as well
   * @returns {Promise<Object>}
   */
  patch({ path, data, headers = {}, config = {}, rawReponse, passHeaders }) {
    return this.request({
      path,
      data,
      headers,
      config,
      method: 'PATCH',
      rawReponse,
      passHeaders,
    });
  }

  /**
   * Perform a PUT request.
   * @param {String} args.path Path of the request
   * @param {Object} args.data The data to send
   * @param {Object} [args.headers={}] Extra headers to set for the request
   * @param {Object} [args.config={}] Extra config values to set for the request
   * @returns {Promise<Object>}
   */
  put({ path, data, headers = {}, config = {} }) {
    return this.request({ path, data, headers, config, method: 'PUT' });
  }

  /**
   * Perform a GET request.
   * @param {String} args.path - Path of the request.
   * @param {Object} [args.headers={}] - Extra headers to set for the request.
   * @param {Object} [args.config={}] - Extra config values to set for the request.
   * @returns {Promise<Object>}
   */
  get({ path, headers = {}, config = {}, rawResponse }) {
    return this.request({ path, headers, config, method: 'GET', rawResponse });
  }

  /**
   * Perform a DELETE request.
   * @param {String} args.path Path of the request
   * @param {String} [args.data] Data to send along with the request
   * @param {Object} [args.headers={}] Extra headers to set for the request
   * @param {Object} [args.config={}] Extra config values to set for the request
   * @returns {Promise<Object>}
   */
  delete({ path, data, headers = {}, config = {} }) {
    return this.request({ path, data, headers, config, method: 'DELETE' });
  }

  /**
   * Perform the fetch-request.
   * @param {String} args.path Path of the request
   * @param {Object} [args.data] The data to send
   * @param {String} [args.method=GET] The fetch method
   * @param {Object} [args.headers={}] Extra headers to set for the request
   * @param {Object} [args.config={}] Extra config values to set for the request
   * @param {Boolean} [args.rawResponse] Return the raw response
   * @param {Boolean} [args.passHeaders] Return the headers as well
   * @returns {Promise<Object>}
   */
  request({
    path,
    data,
    method = 'GET',
    headers = {},
    config = {},
    rawResponse,
    passHeaders,
  }) {
    this.path = path;
    this.data = data;
    this.method = method;
    this.headers = headers;
    this.config = config;
    this.rawResponse = Boolean(rawResponse);
    this.passHeaders = Boolean(passHeaders);

    return this.handleFetch();
  }

  async handleFetch() {
    return await Promise.resolve(null);
  }
}

class TwoFactorRequestProxy extends RequestProxy {
  constructor(user) {
    super(user);

    this.resolve = null;
    this.reject = null;

    this.component = null;
    this.componentWrapped = null;
  }

  destroyComponent() {
    if (this.component) {
      this.component.destroy();
      this.component = null;
    }
  }

  destroyComponentListeners() {
    if (this.componentWrapped) {
      this.componentWrapped.removeAllListeners();
      this.componentWrapped = null;
    }
  }

  setupComponentListeners() {
    if (!this.componentWrapped) {
      this.componentWrapped = wrap(this.component);
      this.componentWrapped
        .on('valid-code', this.handleFetch.bind(this))
        .on('close', this.onTwoFactorAuthClosed.bind(this));
    }
  }

  onTwoFactorAuthClosed() {
    if (!this.resolved) {
      this.resolve(null);
      this.resolved = true;
    }

    this.destroyComponentListeners();
    this.destroyComponent();
  }

  async loadComponent() {
    if (!this.component) {
      const template = await import(
        /* webpackMode: "lazy" */
        /* webpackExports: ["default", "named"] */
        '../components/two-factor-auth/index.marko'
      );

      const render = await template.default.render({
        user: this.user,
      });
      render.appendTo(document.body);

      this.component = render.getComponent();
    }

    return this.component;
  }

  async init() {
    const component = await this.loadComponent();

    this.destroyComponentListeners();
    this.setupComponentListeners();

    component.open();
  }

  // eslint-disable-next-line no-unused-vars
  async handleFetch(twoFactorSession, _code) {
    let content;

    const url = new URL(this.path, window.location.origin);
    const config = {
      credentials: 'same-origin',
      headers: this.headers,
      method: this.method,
      mode: 'same-origin',
      referrerPolicy: 'origin',
      ...this.config,
    };

    if (!isCorsRequest(config.mode) && !isOpaqueRequest(config.mode)) {
      config.headers['X-2FA-Token'] = twoFactorSession;
      if (this.csrfToken()) {
        config.headers['X-CSRF-Token'] = this.csrfToken();
      }
    }

    if (this.data) {
      config.body = this.data;
    }

    const response = await window.fetch(url.href, config);

    this.resolved = true;

    this.component.validating(false);
    this.component.close();

    const { redirected, status, statusText, type, headers } = response;

    if (headers.has('x-csrf-token')) {
      RequestProxy.updateCsrfToken(headers.get('x-csrf-token'));
    }

    if (config.redirect && config.redirect === 'manual') {
      if (type === OPAQUE_REDIRECT_TYPE) {
        return response;
      }
    }

    if (redirected || (status >= 300 && status <= 333)) {
      return {
        redirected: true,
        status,
        statusText,
        headers,
      };
    }

    if (status === 204) {
      this.resolve(response);
      return;
    }

    const contentType = response.headers.get('content-type');
    if (/^application\/json/.test(contentType)) {
      content = await response.json();
    } else if (/^text\/plain/.test(contentType)) {
      content = await response.text();
    } else {
      content = response.body;
    }

    if (isOKStatus(status)) {
      this.resolve(content);
      return;
    }

    this.reject(content);
  }

  /**
   * Perform the fetch-request.
   * @param {String} args.path - Path of the request.
   * @param {Object} [args.data] - The data to send.
   * @param {String} [args.method=GET] - The fetch method.
   * @param {Object} [args.headers={}] - Extra headers to set for the request.
   * @returns {Promise<Object>}
   */
  request({ path, data, method = 'GET', headers = {}, config = {} }) {
    this.path = path;
    this.data = data;
    this.method = method;
    this.headers = headers;
    this.config = config;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.init();
    });
  }
}

class NormalRequestProxy extends RequestProxy {
  constructor(user) {
    super(user);

    this.path = null;
    this.data = null;
    this.headers = null;
    this.method = null;
    this.config = null;
  }

  async handleFetch() {
    let content;

    const url = new URL(this.path, window.location.origin);
    const config = {
      credentials: 'same-origin',
      headers: this.headers,
      method: this.method,
      mode: 'same-origin',
      referrerPolicy: 'origin',
      ...this.config,
    };

    if (!isCorsRequest(config.mode) && !isOpaqueRequest(config.mode)) {
      if (this.csrfToken()) {
        config.headers['X-CSRF-Token'] = this.csrfToken();
      }
    }

    if (this.data) {
      config.body = this.data;
    }

    const response = await window.fetch(url.href, config);
    const { redirected, status, statusText, type, headers } = response;

    if (headers.has('x-csrf-token')) {
      RequestProxy.updateCsrfToken(headers.get('x-csrf-token'));
    }

    if (config.redirect && config.redirect === 'manual') {
      if (type === OPAQUE_REDIRECT_TYPE) {
        return response;
      }
    }

    if (redirected || (status >= 300 && status <= 333)) {
      return {
        redirected: true,
        status,
        statusText,
        headers,
      };
    }

    if (status === 204) {
      return response;
    }

    if (this.rawResponse && isOKStatus(status)) {
      return response;
    }

    const contentType = response.headers.get('content-type');
    if (/^application\/json/.test(contentType)) {
      content = await response.json();
    } else if (/^text\/plain/.test(contentType)) {
      content = await response.text();
    } else {
      content = response.body;
    }

    if (isOKStatus(status)) {
      return this.passHeaders ? [content, headers] : content;
    }

    throw content;
  }
}

/**
 * Return a new request proxy to perform fetch-request with.
 * @param {Object} user The user that is performing the request
 * @returns {RequestProxy}
 */
export function requestProxy(user) {
  if (user && user?.has_2fa && user?.totp) {
    return new TwoFactorRequestProxy(user);
  }
  return new NormalRequestProxy();
}

/**
 * Return a new request proxy to perform fetch-request with.
 * @returns {RequestProxy}
 */
export function normalRequestProxy() {
  return new NormalRequestProxy();
}

export default requestProxy;
